import game1 from "./assets/images/1.png";
import game2 from "./assets/images/2.png";
import game3 from "./assets/images/3.png";
import game4 from "./assets/images/4.png";
import game5 from "./assets/images/5.png";
import game6 from "./assets/images/6.png";

const cards = [
  { id: 1, name: "aurelia", image: game1 },
  { id: 2, name: "aurelia", image: game1 },
  { id: 3, name: "angular", image: game2 },
  { id: 4, name: "angular", image: game2 },
  { id: 5, name: "ember", image: game3 },
  { id: 6, name: "ember", image: game3 },
  { id: 7, name: "vue", image: game4 },
  { id: 8, name: "vue", image: game4 },
  { id: 9, name: "backbone", image: game5 },
  { id: 10, name: "backbone", image: game5 },
  { id: 11, name: "react", image: game6 },
  { id: 12, name: "react", image: game6 },
];

export const cardsData = cards.map((card) => ({
  ...card,
  order: Math.floor(Math.random() * 12),
  isFlipped: false,
}));
