import React, { useState, useEffect } from "react";
import Card from "./Card";
import { cardsData } from "./cardsData";
import looktv_logo from './assets/images/looktv-logo.png';
function Game({onGameOver, promoCount}) {
    
  // states
  let [cardsState, setCardsState] = useState(cardsData);

  // kep first card info
  let [firstCard, setFirstCard] = useState(null);
  // is it first click?
  let [secondClick, setSecondClick] = useState(false);
  // set flag to wait for 1500ms
  let [wait, setWait] = useState(false);

  let [isItOver, setIsItOver] = useState(0);
    useEffect(() => {
        // Example condition to check if the game is over
        if (isItOver === 6) {
          onGameOver();
        }
      }, [isItOver, onGameOver]);

  // functions
  const checker = async (card) => {
    if (card.name === firstCard.name && card.id !== firstCard.id) {
      
      setIsItOver(isItOver + 1);
      card["passed"] = true;
      firstCard["passed"] = true;
      changeCardStatusHandler(card);
      changeCardStatusHandler(firstCard);
     
      setWait(true);
      setTimeout(() => {
        changeCardStatusHandler(card);
        changeCardStatusHandler(firstCard);
        setWait(false);
      }, 1000);
    } else {
      setWait(true);
      setTimeout(() => {
        changeCardStatusHandler(card);
        changeCardStatusHandler(firstCard);
        setWait(false);
      }, 1500);
    }
  };

  const changeCardStatusHandler = async (clickedCard) => {
    if (!clickedCard.passed) clickedCard.isFlipped = !clickedCard.isFlipped;
    let index = cardsState.findIndex((card) => card.id === clickedCard.id);
    let newState = [...cardsState];
    newState.splice(index, 1, clickedCard);
    await setCardsState(newState);
  };

  const handleClick = async (e, clickedCard) => {
    if (wait) {
      return;
    }
    if (!secondClick) {
      await setFirstCard(clickedCard);
      await setSecondClick(true);
      changeCardStatusHandler(clickedCard);
    } else {
      await setSecondClick(false);
      changeCardStatusHandler(clickedCard);
      checker(clickedCard);
      setFirstCard(null);
    }
  };

  return (
    <div>
      <img src={looktv_logo} alt="LookTV" className="logo center" />
      {promoCount > 0 ? (
        <>
          <p className='message'>Ижил дүрүүдийг олоорой.</p>
          <p className='message'>Танд амжилт хүсье!</p>
          <div>
            <section className="memory-game">
              {cardsState?.map((card) => {
                return (
                  <Card
                    key={card.id}
                    card={card}
                    onClick={(e) => handleClick(e, card)}
                  />
                );
              })}
            </section>
          </div>
          <br></br>
          <br></br>
          <p className='game-pass-message'><a className="numberCircle">{promoCount}</a> Эрх байна</p>
        </>
      ) : (
        <p className='end-message'>Танд баярлалаа. Та LookTV-ээс дахин үйлчилгээ идэвхжүүлж урамшуулалд оролцоорой.</p>
      )}
    </div>
  );
}

export default Game;
