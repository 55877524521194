import React from 'react';
import PropTypes from 'prop-types';

const WinScreen = ({ text, gift, title }) => {
    const styles = {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0ad7b0',
        display: 'flex', // Add this line to activate Flexbox
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };

    return (
        <div style={styles}>
            <div>
            <p className='congText'>{title}</p>
            <p className='winText'>{text}</p>
            <p className='winText'>{gift}</p>
            </div>
            
        </div>
    );
};

WinScreen.propTypes = {
    text: PropTypes.string.isRequired,
};

export default WinScreen;